
import {
  defineComponent,
  reactive,
  ref,
  toRaw,
  toRefs,
} from "vue"
import type {UnwrapRef} from "vue"
import ApiLogistics from "@/request/apis/api_logistics"
import {message} from "ant-design-vue/es"
import {IGoodsCateUpdateItem} from "@/models/logistics_model";

interface IState {
  loading: boolean
  editItem?: IGoodsCateUpdateItem
}

export default defineComponent({
  props: ["editItem", "reset"],
  name: "GoodsCateSet",
  setup(props) {
    const state: IState = reactive({
      loading: false,
      list: [],
    })

    const formRef = ref()
    const formState: UnwrapRef<IGoodsCateUpdateItem> = reactive({...props.editItem})

    const rules = {
      name: [
        {
          required: true,
          message: "请输入分类名称",
          trigger: ["change", "blur"],
        },
      ],
      // head_img: [{required: true, message: '请上传头像', trigger: ['change', 'blur']}],
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            onSet()
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    // 新增、编辑
    const onSet = async () => {
      const params = {...toRaw(formState)}
      if(!params.id){
        params.is_enable = 1
      }
      params.weight = Number(params.weight)
      state.loading = true
      const res = await ApiLogistics.setGoodsCate({
        ...toRaw(formState),
      })
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(params.id ? "编辑成功" : "新增成功")
          if (props.reset) {
            props.reset()
          }
          return
        }
        message.error(res.msg)
      }
    }

    return {
      ...toRefs(state),
      formRef,
      labelCol: {span: 6},
      wrapperCol: {span: 18},
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
      onSet
    }
  },
})
